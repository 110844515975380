import React, {useEffect, useState} from "react";
import {useAuth} from "react-oidc-context";
import {AuthHelpers, UserRole} from "../../Utils/AuthHelpers";
import {Prompt} from "../../models/prompt/Prompt";
import {
    Container,
    Paper,
    Typography,
    TextField,
    Button,
    List,
    ListItem,
    ListItemText,
    Divider,
    Box, Checkbox, Chip, Tooltip,
} from "@mui/material";
import {Save} from "lucide-react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {Cancel} from "@mui/icons-material";
import {PromptsRepository} from "../../repositories/PromptsRepository";
import {RandomWords} from "../../Utils/RandomWords";
import {PromptFlowRepository} from "../../repositories/PromptFlowRepository";
import {PromptFlow} from "../../models/promptflow/PromptFlow";

const PromptEditor = () => {
    const auth = useAuth();
    const {categoryId} = useParams();
    const [prompts, setPrompts] = useState<Prompt[]>([]);
    const [promptFlows, setPromptFlows] = useState<PromptFlow[]>([]);
    const [currentPrompt, updateCurrentPrompt] = useState<Prompt>(new Prompt());
    const promptRepo = new PromptsRepository(auth);
    const promptFlowRepo = new PromptFlowRepository(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (!AuthHelpers.isUserInrole(auth, UserRole.SuperAdmin)) {
            return
        }

        if (categoryId === undefined || categoryId === "") {
            updateCurrentPrompt({
                ...currentPrompt, categoryId: RandomWords.generateRandomName(),
            })
        } else {
            getPrompts(categoryId).catch(e => console.error(e));
            getPromptFlows(categoryId).catch(e => console.error(e));
        }
    }, []);

    const getPrompts = async (category: string) => {
        const prompts = await promptRepo.getPrompts(category);
        if (prompts !== undefined) {
            setPrompts(prompts);
            let activePrompt = prompts.find(p => p.isActive);
            if (activePrompt) {
                updateCurrentPrompt(activePrompt);
            }
        }
    }
    
    const getPromptFlows = async (category: string) => {
        const promptFlows = await promptFlowRepo.flowsForCategory(category);
        if (promptFlows !== undefined) {
            setPromptFlows(promptFlows);
            console.log(promptFlows)
        }
    }

    const handleCategoryIdChange = (e: any) => {
        updateCurrentPrompt({
            ...currentPrompt, categoryId: e.target.value,
        })
    };

    const handleCategoryNameChange = (e: any) => {
        updateCurrentPrompt({
            ...currentPrompt, categoryName: e.target.value,
        })
    };

    const handlePromptNameChange = (e: any) => {
        updateCurrentPrompt({
            ...currentPrompt, name: e.target.value,
        })
    }

    const handlePromptChange = (e: any) => {
        updateCurrentPrompt({
            ...currentPrompt, text: e.target.value,
        })
    }

    const handleSavePrompt = async () => {
        if (!categoryId || categoryId === "" && currentPrompt.categoryId) {
            // alert("Invalid state, no category!"); 
            // return;
        } else {
            currentPrompt.categoryId = categoryId;
        }
        // only allow creating new prompts, so an edit is always a new version?
        currentPrompt.promptId = -1;

        const result = await promptRepo.addPrompt(currentPrompt);
        if (result !== undefined) {
            if (categoryId && categoryId !== "") {
                await getPrompts(categoryId);
                updateCurrentPrompt(new Prompt());
            } else {
                navigate({
                    pathname: `/prompts/edit/${currentPrompt.categoryId}`,
                });
            }
        }
    }
    
    const handleCancelPrompt = () => {
        updateCurrentPrompt(new Prompt());
    }

    const showPromptFlow = (id: any) => {
        window.open(`/promptflows/edit/${id}`, "_blank");
    };


    // const deletePrompt = async (prompt: Prompt) => {
    //     if (!window.confirm(`Weet u zeker dat u prompt ${prompt.name} wilt verwijderen?`)) {
    //         return
    //     }
    //    
    //     const result = await promptRepo.deletePrompt(prompt);
    //     if (result) {
    //         await getPrompts(categoryId);
    //     }
    // }

    if (!AuthHelpers.isUserInrole(auth, UserRole.SuperAdmin)) {
        return (
            <div>Unauthorized</div>
        );
    }

    return (
        <Container maxWidth="md" sx={{mt: 4, mb: 4}}>
            <Paper elevation={3} sx={{p: 3, bgcolor: "background.paper"}}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: 3,
                    }}
                >
                    <img
                        src="/Logo.svg"
                        alt="Rake Voorstellen Logo"
                        style={{height: "50px"}}
                    />
                </Box>

                <Typography variant="h4" gutterBottom color="primary">
                    Prompt Editor
                </Typography>
                {categoryId === undefined ? <>
                    <TextField
                        fullWidth
                        rows={4}
                        variant="outlined"
                        value={currentPrompt.categoryId}
                        onChange={handleCategoryIdChange}
                        placeholder="Prompt categorie id"
                        sx={{mb: 2}}
                    />
                </> : <></>}
                <TextField
                    fullWidth
                    variant="outlined"
                    value={currentPrompt.categoryName}
                    onChange={handleCategoryNameChange}
                    placeholder="Prompt categorienaam"
                    sx={{mb: 2}}
                />
                <Box sx={{mb: 2, display: "flex", flexWrap: "wrap"}}>
                    <Typography variant="subtitle1" sx={{ml: 1}} color="Primary">Gebruikt in flows:</Typography>
                    {promptFlows.map((f) => (
                        <Tooltip key={f.id + 'tt'} title={f.description}>
                            <Chip key={f.id}
                                  label={f.name}
                                  sx={{ml: 1}}
                                  onClick={() => showPromptFlow(f.id)}/>
                        </Tooltip>
                    ))}
                </Box>
                <TextField
                    fullWidth
                    variant="outlined"
                    value={currentPrompt.name}
                    onChange={handlePromptNameChange}
                    placeholder="Voer hier de prompt naam in..."
                    sx={{mb: 2}}
                />
                <TextField
                    fullWidth
                    multiline
                    rows={12}
                    variant="outlined"
                    value={currentPrompt.text}
                    onChange={handlePromptChange}
                    placeholder="Voer hier de nieuwe prompt in..."
                    sx={{mb: 2}}
                />
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<Save/>}
                    onClick={handleSavePrompt}
                    sx={{mb: 3}}
                >
                    Opslaan
                </Button>
                {currentPrompt.promptId > 0
                    ? <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<Cancel/>}
                        onClick={handleCancelPrompt}
                        sx={{mb: 3, ml: 3}}
                    >Annuleren</Button>
                    : <></>
                }

                <Divider sx={{my: 3}}/>

                <Box sx={{display: "flex", alignItems: "center", mb: 2}}>
                    {/*<History size={24} color={theme.palette.secondary.main} />*/}
                    <Typography variant="h5" sx={{ml: 1}} color="secondary">
                        Prompt Geschiedenis
                    </Typography>
                </Box>
                <List>
                    {prompts.map((prompt) => (
                        <React.Fragment key={prompt.promptId}>
                            <ListItem alignItems="flex-start"
                                      onClick={(e) => updateCurrentPrompt(prompt)}
                                      sx={prompt.promptId === currentPrompt.promptId
                                          ? {
                                              color: "primary.contrastText",
                                              backgroundColor: "primary.main"
                                          }
                                          : {cursor: "pointer"}}
                            >
                                {prompt.isActive
                                    ? <Checkbox
                                        edge="start"
                                        checked={prompt.isActive}
                                        tabIndex={-1}
                                        disableRipple
                                        checkedIcon={<CheckCircleOutlineIcon
                                            color={prompt.promptId !== currentPrompt.promptId ? "primary" : "secondary"}/>}

                                    />
                                    : <></>
                                }
                                <ListItemText
                                    primary={prompt.name}
                                    secondary={
                                        <Typography
                                            sx={{display: "inline"}}
                                            component="span"
                                            variant="body2"
                                        >
                                            {prompt.text}
                                            <br/>
                                            Gewijzigd: {prompt.updatedAt.toLocaleString()}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                            {/*<IconButton aria-label={"Delete"}*/}
                            {/*            onClick={() => {*/}
                            {/*                deletePrompt(prompt).catch();*/}
                            {/*            }}*/}
                            {/*><TrashIcon /></IconButton>*/}
                            <Divider component="li"/>
                        </React.Fragment>
                    ))}
                </List>
            </Paper>
        </Container>

    );
}

export default PromptEditor;